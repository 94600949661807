<template>
  <div>

    <el-row>
      <el-col :span="4">
        <h4>{{dialogTitle}}</h4>
      </el-col>
      <el-col :span="20" class="text-right">
          <el-button type="primary" @click="onSaveDiagnosis({resetForm: true})">Neue Diagnose</el-button>
          <el-button type="primary" @click="onSaveDiagnosis({resetForm: false})">{{dialogSaveButtonTitle}}</el-button>
      </el-col>
    </el-row>

    <el-tabs>
      <el-tab-pane label="Diagnose">


        <el-row class="mt-4">
          <el-col :span="8">
            <date-picker-precision v-model="currentDiagnosis.date" v-model:precision="currentDiagnosis.date_precision"></date-picker-precision>
          </el-col>
          <el-col :span="10" :offset="1">
            <h6>Primärdiagnose?</h6>
            <el-col>
              <el-checkbox v-model="currentDiagnosis.is_primary">Ja, dies ist eine Primärdiagnose</el-checkbox>
            </el-col>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>Diagnose</h6>
            <el-select size="medium" class="w-100" v-model="currentDiagnosis.diagnosis_id" filterable @change="onDiagnoseChange">
              <el-option
                v-for="item in diagnosis"
                :key="item.id"
                :label="(item.code + ', ' + item.title)"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10" :offset="1">
            <h6>Metastasen</h6>
            <el-select size="medium" class="w-100" v-model="currentDiagnosis.diagnosis_metastasis_id" filterable :disabled="currentDiagnosis.is_primary">
              <el-option :value="null">-- Keine Metastasen --</el-option>
              <el-option
                v-for="item in diagnosismetastasis"
                :key="item.id"
                :label="(item.code + ', ' + item.title)"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-4" v-if="currentDiagnosis.is_primary === false && currentDiagnosis.diagnosis_metastasis_id">
          <el-col :span="8">
          </el-col>
          <el-col :span="10" :offset="1">
            <h6>Primärdiagnose</h6>
            <el-select size="medium" class="w-100" v-model="currentDiagnosis.primary_diagnosis_id" filterable>
              <el-option :value="null">-- Keine zugehörige Primärdiagnose --</el-option>
              <el-option
                v-for="item in primaryDiagnosis"
                :key="item.id"
                :label="($filters.germanDate(item.date) + ', ' + item.meta.diagnosis.title)"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>TNM</h6>
            <TNM v-model="currentDiagnosis.tnm" context="static" :hide-heaader="true"></TNM>

            <h6 class="mt-4">Diagnosesicherheit
              <el-tooltip
                content="Diagnose gesichert"
                placement="bottom"
                v-if="currentDiagnosis.certainty == 4 || currentDiagnosis.certainty == 5 || currentDiagnosis.certainty == 6 || currentDiagnosis.certainty == 7">
                <el-tag size="mini" type="warning" class="mr-1" >P</el-tag>
              </el-tooltip>
              <el-tooltip
                content="klinische Diagnose"
                placement="bottom"
                v-if="currentDiagnosis.certainty == 1 || currentDiagnosis.certainty == 2">
                <el-tag size="mini" type="warning" class="mr-1" >C</el-tag>
              </el-tooltip>
              <el-tooltip
                content="Verdacht auf"
                placement="bottom"
                v-if="currentDiagnosis.certainty == 'V'">
                <el-tag size="mini" type="warning" class="mr-1" >S</el-tag>
              </el-tooltip>
            </h6>
            <el-select size="medium" class="w-100" v-model="currentDiagnosis.certainty" filterable>
              <el-option :value="null">-- Nicht ausgewählt --</el-option>
              <el-option value="V" label="Verdacht anhand bildgebender oder anderer Diagnostik"></el-option>
              <el-option value="1" label="Klinische Diagnose ohne bildgebende oder andere Diagnostik"></el-option>
              <el-option value="2" label="Klinische Diagnose anhand bildgebender oder anderer Diagnostik"></el-option>
              <el-option value="4" label="Diagnose anhand einer tumorspezifischen Laboruntersuchung"></el-option>
              <el-option value="5" label="Zytologische Diagnose"></el-option>
              <el-option value="6" label="Histologische Diagnose anhand einer Metastase"></el-option>
              <el-option value="7" label="Histologische Diagnose anhand des Primärtumors"></el-option>
            </el-select>
          </el-col>
          <el-col :span="12" :offset="1">

            <el-row>
              <el-col :span="4">
                <h6>Position</h6>
                <el-select size="medium" class="w-100" v-model="currentDiagnosis.position" filterable placeholder="Nicht relevant">
                  <el-option :value="null">Nicht relevant</el-option>
                  <el-option value="L" label="Links">Links</el-option>
                  <el-option value="R" label="Rechts">Rechts</el-option>
                  <el-option value="M" label="Mittig">Mittig</el-option>
                </el-select>
              </el-col>
              <el-col :span="9" :offset="1">
                <h6>ICD-O3-T</h6>
                <el-select size="medium" class="w-100" v-model="currentDiagnosis.icdo3t" filterable>
                  <el-option :value="null">-- Nichts ausgewählt --</el-option>
                  <el-option
                    v-for="item in ICDO3T"
                    :key="item.id"
                    :label="(item.ICDO3T.code + ' ' + (item.default ? '*** ' : '') + item.ICDO3T.description)"
                    :value="item.ICDO3T.code">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="9" :offset="1">
                <h6>ICD-O3-H</h6>
                <el-select size="medium" class="w-100" v-model="currentDiagnosis.icdo3h" filterable>
                  <el-option :value="null">-- Nichts ausgewählt --</el-option>
                  <el-option
                    v-for="item in ICDO3H"
                    :key="item.id"
                    :label="(item.ICDO3H.code + ' ' + (item.default ? '*** ' : '') + item.ICDO3H.description)"
                    :value="item.ICDO3H.code">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <h6 class="mt-4">Kommentar</h6>
            <el-input type="textarea" v-model="currentDiagnosis.comment"></el-input>
          </el-col>
        </el-row>

        <h5 class="mt-4">Zugewiesene Befunde</h5>

        <el-row class="mt-1">
          <el-col :span="24">

            <el-table
              ref="findingstable"
              :data="currentDiagnosis.findings"
              style="width: 100%"
              stripe>
              <el-table-column
                prop="date"
                label="Datum"
                width="150"
                sortable>
                <template #default="scope">
                  <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="diagnostic_method_id"
                label="Diagnostisches Verfahren">
                <template #default="scope">
                  <span v-if="scope.row.meta && scope.row.meta.diagnosticmethod">{{ scope.row.meta.diagnosticmethod.title }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="evaluation"
                label="Bewertung">
                <template #default="scope">
                  <span v-if="scope.row.evaluation">{{ scope.row.evaluation }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="70">
                <template #default="scope">
                  <div class="text-right">

                    <el-popconfirm
                      :title="'Sie möchten die Zuweisung zum Befund vom ' + $filters.germanDate(scope.row.date) + ' löschen?'"
                      confirmButtonText='Ja, löschen'
                      confirmButtonType="danger"
                      cancelButtonText='Nein'
                      iconColor="red"
                      @confirm="onDeleteFinding(scope.row)">
                      <template #reference>
                        <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
                      </template>
                    </el-popconfirm>

                  </div>
                </template>
              </el-table-column>
            </el-table>

          </el-col>
        </el-row>

        <el-row class="mt-4" v-if="currentPatient && selectableFindings.length > 0">
          <el-col :span="10">
            Befund zuweisen:
            <el-select placeholder="Befund auswählen" size="medium" class="ml-3" v-model="selectedFinding" filterable>
              <el-option
                v-for="item in selectableFindings"
                :key="item.id"
                :label="($filters.germanDate(item.date) + ', ' + (item.meta ? item.meta.diagnosticmethod.title+', ' : '') + item.evaluation)"
                :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4" v-else>
          <el-col :span="24">
            Es sind keine weiteren Befunde zur Zuweisung vorhanden
          </el-col>
        </el-row>

      </el-tab-pane>
      <el-tab-pane label="Diagnosemodul">
        <el-row v-if="diagnosisModule" class="mt-4">
          <el-col :span="12" v-for="item in diagnosisModule.items" :key="item.id" class="mb-4">
            <h6>{{item.title}}</h6>
            <div v-if="item.evaluation_options">
              <el-select size="medium" filterable class="w-75" v-model="currentDiagnosisModuleItems[item.id]" :style="(item.required && currentDiagnosisModuleItems[item.id].value === null ? 'border-radius:5px; border:1px #A00 solid;' : '')">
                <el-option
                  v-for="item in item.evaluation_options"
                  :key="item.id"
                  :label="item.label"
                  :value="{ value: item.value, evaluation: item.evaluation || null }">
                </el-option>
              </el-select>
              <small class="d-block" v-if="item.evaluation">{{item.evaluation}}</small>
            </div>
            <div v-else-if="item.unit == 'date'">
              <el-date-picker
                :style="(item.required && currentDiagnosisModuleItems[item.id].value === null ? 'border-radius:5px; border:1px #A00 solid;' : '')"
                v-if="currentDiagnosisModuleItems[item.id]"
                v-model="currentDiagnosisModuleItems[item.id].value"
                class="w-75"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Datum auswählen">
              </el-date-picker>
            </div>
            <div v-else>
              <el-input show-word-limit :style="(item.required && currentDiagnosisModuleItems[item.id].value === null ? 'border-radius:5px; border:1px #A00 solid;' : '') + ' ' + (item.length ? 'width:' + (item.length*22) + 'px' : 'width:75%')" v-if="currentDiagnosisModuleItems[item.id]" :maxlength="(item.length ? item.length : 255)"  v-model="currentDiagnosisModuleItems[item.id].value"></el-input>
              <small class="d-block" v-if="item.evaluation">{{item.evaluation}}</small>
            </div>
          </el-col>
        </el-row>
        <div v-else>
          Für die gewählte Primärdiagnose ist kein Modul vorhanden
        </div>
      </el-tab-pane>

      <el-tab-pane label="TNM">
        <TNM v-model="currentDiagnosis.tnm" :diagnosis_id="currentDiagnosis.diagnosis_id" :icdo3t="currentDiagnosis.icdo3t" :icdo3h="currentDiagnosis.icdo3h" context="diagnosis"></TNM>
      </el-tab-pane>

      <el-tab-pane label="Tumorkonferenzen">


        <el-button type="primary" @click="onBtnNewTumorconference" size="mini" icon="el-icon-circle-plus" class="mt-3 mb-3">Neue Tumorkonferenz</el-button>
        <el-tabs>

          <el-tab-pane v-for="(conference, index) in currentDiagnosis.tumorconferences" :key="index" class="mt-3" :label="('Tumorkonferenz ' + (conference.date ? $filters.germanDate(conference.date) : (index+1)))">

            <el-row>
              <el-col :span="6">
                <date-picker-precision v-model="conference.date" v-model:precision="conference.date_precision" label_date="Datum"></date-picker-precision>
              </el-col>
            </el-row>

            <el-row class="mt-4">
              <el-col :span="12">
                <h6>Typ</h6>
                <el-select size="medium" class="w-100" v-model="conference.type" filterable>
                  <el-option :value="null">-- Nicht ausgewählt --</el-option>
                  <el-option value="praeth" label="Prätherapeutische Tumorkonferenz (Festlegung der Therapiestrategie)"></el-option>
                  <el-option value="postop" label="postoperative Tumorkonferenz (Planung der postoperativen Therapie, z.B. zur Frage adjuvante Therapie)"></el-option>
                  <el-option value="postth" label="posttherapeutische Tumorkonferenz (manche Tumoren werden nicht operiert)"></el-option>
                  <el-option value="ther" label="Therapieplanung ohne Tumorkonferenz"></el-option>
                </el-select>
              </el-col>
            </el-row>

            <h6 class="mt-4">Therapieempfehlungen</h6>
            <el-row>
              <el-col :span="5" class="mb-2 mr-2" v-for="(value, index) in conference.therapy_recommendations" :key="index">
                <el-select size="medium" class="w-100" v-model="conference.therapy_recommendations[index]" filterable>
                  <el-option :value="null">-- Nicht ausgewählt --</el-option>
                  <el-option value="AS" label="Active Surveillance"></el-option>
                  <el-option value="CH" label="Chemotherapie"></el-option>
                  <el-option value="CZ" label="Chemotherapie + zielgerichtete Substanzen"></el-option>
                  <el-option value="CI" label="Chemotherapie + Immun-/Antikörpertherapie"></el-option>
                  <el-option value="CIZ" label="Chemotherapie + Immun-/Antikörpertherapie + zielgerichtete Substanzen"></el-option>
                  <el-option value="HO" label="Hormontherapie"></el-option>
                  <el-option value="IM" label="Immun-/Antikörpertherapie"></el-option>
                  <el-option value="IZ" label="Immun-/Antikörpertherapie + zielgerichtete Substanzen"></el-option>
                  <el-option value="KW" label="Keine weitere tumorspezifische Therapie empfohlen"></el-option>
                  <el-option value="OP" label="Operation"></el-option>
                  <el-option value="SO" label="Sonstiges"></el-option>
                  <el-option value="SZ" label="Stammzelltransplantation (inkl. Knochenmarktransplantation)"></el-option>
                  <el-option value="ST" label="Strahlentherapie"></el-option>
                  <el-option value="WS" label="Wait and see"></el-option>
                  <el-option value="WW" label="Watchful Waiting"></el-option>
                  <el-option value="ZS" label="Zielgerichtete Substanzen"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-button class="mt-1" type="primary" @click="onBtnNewTherapyRecommendation(conference)" size="mini" icon="el-icon-circle-plus"></el-button>
              </el-col>
            </el-row>

            <el-row class="mt-4">
              <el-col :span="12">
                <h6>Abweichung Patientenwunsch</h6>
                <el-select v-model="conference.patient_deviation" class="w-100">
                  <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                  <el-option label="Ja" value="J"></el-option>
                  <el-option label="Nein" value="N"></el-option>
                  <el-option label="Unbekannt" value="U"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>

      </el-tab-pane>
      <el-tab-pane label="Sonstiges">

        <el-row v-if="commonModule" class="mt-4">
          <el-col :span="12" v-for="item in commonModule.items" :key="item.id" class="mb-4">
            <h6>{{item.title}}</h6>
            <div v-if="item.evaluation_options">
              <el-select size="medium" filterable class="w-75"  v-model="currentDiagnosisModuleItems[item.id]">
                <el-option
                  v-for="item in item.evaluation_options"
                  :key="item.id"
                  :label="item.label"
                  :value="{ value: item.value, evaluation: item.evaluation || null }">
                </el-option>
              </el-select>
            </div>
            <div v-else-if="item.unit == 'date'">
              <el-date-picker
                v-if="currentDiagnosisModuleItems[item.id]"
                v-model="currentDiagnosisModuleItems[item.id].value"
                class="w-75"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Datum auswählen">
              </el-date-picker>
            </div>
            <div v-else>
              <el-input show-word-limit :style="(item.length ? 'width:' + (item.length*22) + 'px' : 'width:75%')" v-if="currentDiagnosisModuleItems[item.id]" :maxlength="(item.length ? item.length : 255)"  v-model="currentDiagnosisModuleItems[item.id].value"></el-input>
              <small class="d-block" v-if="item.evaluation">{{item.evaluation}}</small>
            </div>
          </el-col>
        </el-row>

      </el-tab-pane>
    </el-tabs>

    <el-row class="mt-5">
      <el-col :span="12">&nbsp;
        <el-popconfirm
          v-if="currentDiagnosis.id > 0"
          :title="'Sie möchten diese Diagnose vom ' + $filters.germanDate(currentDiagnosis.date) + ' löschen?'"
          confirmButtonText='Ja, löschen'
          confirmButtonType="danger"
          cancelButtonText='Nein'
          iconColor="red"
          @confirm="onDeleteDiagnosis">
          <template #reference>
            <el-button type="danger" icon="el-icon-delete">Diagnose löschen</el-button>
          </template>
        </el-popconfirm>

        <el-button v-if="currentDiagnosis.tumorconferences && currentDiagnosis.tumorconferences.length === 0" type="secondary" icon="el-icon-download" class="ml-3" @click="onGenerateCancerRegistryXML('diagnosis', null)">Krebsregister XML</el-button>
        <el-dropdown v-else icon="el-icon-download" class="ml-3">
          <el-button type="secondary" icon="el-icon-download">
            Krebsregister
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onGenerateCancerRegistryXML('diagnosis', null)">Diagnose</el-dropdown-item>
              <div v-for="(tumorconference, index) in currentDiagnosis.tumorconferences" :key="tumorconference.id" >
                <el-dropdown-item v-if="tumorconference.id > 0" @click="onGenerateCancerRegistryXML('tumorconference', null, index)">Tumorkonferenz {{ $filters.germanDate(tumorconference.date) }}</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      </el-col>
      <el-col :span="12" class="text-right">
        <el-button type="primary" @click="$router.push({ name: 'finding' })">Befunde</el-button>
        <el-button type="primary" @click="onSaveDiagnosis({resetForm: true})">Neue Diagnose</el-button>
        <el-button type="primary" @click="onSaveDiagnosis({resetForm: false})">{{dialogSaveButtonTitle}}</el-button>
      </el-col>
    </el-row>



 </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import DatePickerPrecision from "../parts/DatePickerPrecision.vue";
import TNM from "../parts/TNM.vue";
const _ = require('lodash');

export default {
  name: "PatientDiagnosisForm",
  props: [],
  components: {TNM, DatePickerPrecision},
  data() {
    return {
      newDiagnosisTemplate: {
        date: moment().format("YYYY-MM-DD"),
        findings: [],
        meta: {},
        is_primary: false,
        date_precision: 'd',
        module_items: [],
        tumorconferences: [],
      },
      currentDiagnosis: {},
      currentDiagnosisModuleItems: [],
      selectedFinding: null,
      selectableFindings: [],
      editMode: false,
    }
  },
  mounted: async function () {

    if(this.$route.params.patient_diagnosis_id) {
      this.editMode = true
    }

    this.currentDiagnosis = _.cloneDeep(this.newDiagnosisTemplate)

    await this.getDiagnosis()
    await this.getDiagnosisMetastasis()
    await this.getDiagnosisModules()

    if(this.$route.params.patient_diagnosis_id) {
      await this.loadDiagnosisOfPatient()
    }


  },
  computed: {
    ...mapGetters('patients', ['currentPatient']),
    ...mapGetters('diagnosis', ['diagnosis']),
    ...mapGetters('diagnosismetastasis', ['diagnosismetastasis']),
    ...mapGetters('diagnosis_modules', ['diagnosis_modules']),
    ...mapGetters('config', ['modules_enabled']),
    ...mapGetters('ICD', ['ICDO3T', 'ICDO3H']),
    dialogTitle() {
      return (this.editMode ? 'Diagnose' : 'Neue Diagnose')
    },
    dialogSaveButtonTitle() {
      return 'Diagnose speichern'
    },
    primaryDiagnosis() {
      if(this.currentPatient) {
        return _.filter(this.currentPatient.diagnosis, (d) => d.id !== this.currentDiagnosis.id && d.is_primary === true)
      }
      return []
    },
    diagnosisModule() {

      if(this.currentPatient) {
        const primary_patdiag_id = this.currentDiagnosis.is_primary ? this.currentDiagnosis.id : this.currentDiagnosis.primary_diagnosis_id
        const patientDiagnosisPrimary = _.find(this.currentPatient.diagnosis, (o) => o.id == primary_patdiag_id)
        if(patientDiagnosisPrimary) {
          const exist = _.find(this.diagnosis_modules, (o) => o.diagnosis_id == patientDiagnosisPrimary.diagnosis_id)
          if(exist) {
            return exist
          }
        }
      }

      return null
    },
    commonModule() {
      const exist = _.find(this.diagnosis_modules, (o) => o.diagnosis_id == 68)
      if(exist) return exist
      return null
    }
  },
  watch: {
    '$route.params.patient_diagnosis_id': async function (val) {
      if(val !== undefined && val > 0) await this.loadDiagnosisOfPatient()
    },
    'currentPatient': function() {
      this.selectableFindings = _.cloneDeep(this.currentPatient.findings)
    },
    'selectedFinding': function(finding) {

      if(finding) {

        const exist = _.find(this.currentDiagnosis.findings, (o) => o.id ==finding.id)
        if(exist) return

        this.currentDiagnosis.findings.push(finding)
        this.selectedFinding = null
      }

    },
    'currentDiagnosis.primary_diagnosis_id': function(primary_diagnosis_id) {
      if(!this.editMode && primary_diagnosis_id) {
        const primaryDiag = _.find(this.primaryDiagnosis, (o) => o.id ==primary_diagnosis_id)
        if(primaryDiag) this.currentDiagnosis.diagnosis_id = primaryDiag.diagnosis_id
      }
    },
  },
  methods: {
    ...mapActions('patients', ['saveDiagnosisToPatient', 'getDiagnosisOfPatient', 'deleteDiagnosisOfPatient']),
    ...mapActions('diagnosis', ['getDiagnosis']),
    ...mapActions('diagnosismetastasis', ['getDiagnosisMetastasis']),
    ...mapActions('diagnosis_modules', ['getDiagnosisModules']),
    ...mapActions('cancerregistry', ['generateDiagnosisXml', 'generateTumorconferenceXml']),
    ...mapActions('ICD', ['getICDO3T', 'getICDO3H']),
    async loadDiagnosisOfPatient() {
      this.editMode = true
      this.currentDiagnosis = await this.getDiagnosisOfPatient({patient_id: this.$route.params.id, patient_diagnosis_id: this.$route.params.patient_diagnosis_id})

      if(this.diagnosisModule) {
        for(const item of this.diagnosisModule.items) {
          this.currentDiagnosisModuleItems[item.id] = { value: null, evaluation: null }
        }
      }

      if(this.commonModule) {
        for(const item of this.commonModule.items) {
          this.currentDiagnosisModuleItems[item.id] = { value: null, evaluation: null }
        }
      }

      for(const item of this.currentDiagnosis.module_items) {
        this.currentDiagnosisModuleItems[item.diagnose_module_item_id] = item.value_evaluation
      }

      await this.getICDO3T(this.currentDiagnosis.diagnosis_id)
      await this.getICDO3H(this.currentDiagnosis.diagnosis_id)

    },
    async onDiagnoseChange() {
      await this.getICDO3T(this.currentDiagnosis.diagnosis_id)
      await this.getICDO3H(this.currentDiagnosis.diagnosis_id)
      this.currentDiagnosis.icdo3t = _.find(this.ICDO3T, (o) => o.default === true)?.ICDO3T.code
      this.currentDiagnosis.icdo3h = _.find(this.ICDO3H, (o) => o.default === true)?.ICDO3H.code
    },
    onDeleteFinding(finding) {
      _.remove(this.currentDiagnosis.findings, (o) => o.id == finding.id)
    },
    async onDeleteDiagnosis() {
      if(this.currentDiagnosis.id > 0) {
        const result = await this.deleteDiagnosisOfPatient(this.currentDiagnosis)

        if(result.status === 200) {
          this.$notify({
            message: 'Diagnose wurde gelöscht',
            type: 'success',
            position: 'bottom-left'
          });

          this.$router.push({ name: 'dashboard'})
        }
      else {
          this.$notify({
            message: 'Ein Fehler trat auf',
            type: 'error',
            position: 'bottom-left'
          });
        }
      }
    },
    async onSaveDiagnosis(options) {

      if(!this.currentDiagnosis.diagnosis_id || this.currentDiagnosis.diagnosis_id === 0) {
        this.$notify({
          message: 'Diagnose wurde noch nicht ausgewählt',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }

      if(this.currentDiagnosis.is_primary === false && this.currentDiagnosis.diagnosis_metastasis_id && !this.currentDiagnosis.primary_diagnosis_id){
        this.$notify({
          message: 'Bei Angabe einer Metastase: Bitte wählen Sie eine Primärdiagnose aus',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }

      this.currentDiagnosis.date = moment(this.currentDiagnosis.date).format("YYYY-MM-DD")
      this.currentDiagnosis.patient_id = this.currentPatient.id

      if(this.currentDiagnosis.findings.length) {
        for(const finding of this.currentDiagnosis.findings) {
          delete finding.meta
        }
      }

      this.currentDiagnosis.module_items = []
      for(const diagnose_module_item_id in this.currentDiagnosisModuleItems) {
        if(this.currentDiagnosisModuleItems[diagnose_module_item_id] === undefined || this.currentDiagnosisModuleItems[diagnose_module_item_id].value === null) continue
        this.currentDiagnosis.module_items.push({
          diagnose_module_item_id: parseInt(diagnose_module_item_id),
          value: this.currentDiagnosisModuleItems[diagnose_module_item_id].value || null,
          evaluation: this.currentDiagnosisModuleItems[diagnose_module_item_id].evaluation || null,
        })
      }


      let hasNewTumorconference = false
      if(this.currentDiagnosis.tumorconferences) {

        for(const conference of this.currentDiagnosis.tumorconferences) {
          let tumorconference_therapy_recomm = []
          if(conference.id == 0) hasNewTumorconference = true
          for (const recomm of conference.therapy_recommendations) {
            if (recomm !== null) tumorconference_therapy_recomm.push(recomm)
          }
          conference.therapy_recommendations = tumorconference_therapy_recomm
        }

      }
      const result = await this.saveDiagnosisToPatient(this.currentDiagnosis)


      this.$notify({
        message: 'Diagnose wurde gespeichert',
        type: 'success',
        position: 'bottom-left'
      });

      if(!this.editMode && result.id && this.modules_enabled.cancerregistry) {
        this.$messageBox.confirm('Soll diese Diagnose dem Krebsregister gemeldet werden?', 'Krebsregister', { confirmButtonText: 'Ja', cancelButtonText: 'Nein', type: 'warning' }).then(async () => {
          this.onGenerateCancerRegistryXML('diagnosis', result.id)
        })
      }

      if(hasNewTumorconference && this.modules_enabled.cancerregistry) {
        this.$messageBox.confirm('Sollen die neue(n) Tumorkonferenz(en) dem Krebsregister gemeldet werden?', 'Krebsregister', { confirmButtonText: 'Ja', cancelButtonText: 'Nein', type: 'warning' }).then(async () => {

        })
      }

      if(options.resetForm) {
        this.editMode = false
        this.currentDiagnosis = _.cloneDeep(this.newDiagnosisTemplate)
        this.$router.push({ name: 'diagnosis'})
      }
      else {
        this.$router.push({ name: 'dashboard' })
        /* if(this.editMode) await this.loadDiagnosisOfPatient()
        else {
          this.editMode = true
          this.$router.push({ name: 'edit_diagnosis', params: { patient_diagnosis_id: result.id }})
        } */
      }

    },
    onBtnNewTherapyRecommendation(conference) {
      conference.therapy_recommendations.push(null)
    },
    onBtnNewTumorconference() {
      this.currentDiagnosis.tumorconferences.push({
        id: 0,
        type: null,
        date: moment().format("YYYY-MM-DD"),
        date_precision: 'd',
        patient_diagnosis_id: this.currentDiagnosis.id,
        patient_deviation: null,
        therapy_recommendations: []
      })
    },
    async onGenerateCancerRegistryXML(mode, diagnosis_id, tumorconference_index) {

      let xmlDiagnosis = _.cloneDeep(this.currentDiagnosis)

      if(mode === 'diagnosis') {

        xmlDiagnosis.id = diagnosis_id ? diagnosis_id : this.currentDiagnosis.id
        const xmlResult = await this.generateDiagnosisXml(xmlDiagnosis)
        this.$notify({
          message: 'Meldung an das Krebsregister wurde ' + ( xmlResult ? '' : 'nicht ' ) + 'generiert',
          type: (xmlResult ? 'success' : 'error'),
          position: 'bottom-left'
        });

      }

      if(mode === 'tumorconference') {

        xmlDiagnosis.tumorconferences = [ xmlDiagnosis.tumorconferences[tumorconference_index] ]

        const xmlResult = await this.generateTumorconferenceXml(xmlDiagnosis)
        this.$notify({
          message: 'Meldung an das Krebsregister wurde ' + ( xmlResult ? '' : 'nicht ' ) + 'generiert',
          type: (xmlResult ? 'success' : 'error'),
          position: 'bottom-left'
        });

      }


    }
  }
}
</script>
<style scoped>
</style>
